import { SidebarLinks } from "@/interfaces/candidate/nav_side_bar";
import { AppRoutes } from "@/interfaces";

const SidebarContent: SidebarLinks[] = [
  {
    name: "nav-sidebar.dashboard",
    link: "/candidate/dashboard",
    icon: require("@/assets/icons/nav_sidebar/dashboard.svg"),
    active_icon: require("@/assets/icons/nav_sidebar/active/dashboard.svg"),
    module: ["candidate"],
    children: []
  },
  {
    name: "nav-sidebar.jobs",
    link: "/candidate/jobs",
    icon: require("@/assets/icons/nav_sidebar/jobs.svg"),
    active_icon: require("@/assets/icons/nav_sidebar/active/jobs.svg"),
    module: ["candidate"],
    children: []
  },
  {
    name: "nav-sidebar.interviews",
    link: "/candidate/interviews",
    icon: require("@/assets/icons/nav_sidebar/teacher.svg"),
    active_icon: require("@/assets/icons/nav_sidebar/active/teacher.svg"),
    module: ["candidate"],
    children: []
  },
  {
    name: "nav-sidebar.assessment",
    link: AppRoutes.Candidae.ASSESSMENTS,
    icon: require("@/assets/icons/nav_sidebar/assessment.svg"),
    active_icon: require("@/assets/icons/nav_sidebar/active/assessment.svg"),
    module: ["candidate"],
    children: [
      {
        name: "nav-sidebar.personality",
        link: AppRoutes.Candidae.PERSONALITY_ASSESSMENT,
        icon: require("@/assets/icons/nav_sidebar/assessment.svg"),
        active_icon: require("@/assets/icons/nav_sidebar/active/assessment.svg"),
        module: ["candidate"],
        children: []
      },
      {
        name: "nav-sidebar.my-next",
        link: AppRoutes.Candidae.MY_NEXT_MOVE_ASSESSMENT,
        icon: require("@/assets/icons/nav_sidebar/assessment.svg"),
        active_icon: require("@/assets/icons/nav_sidebar/active/assessment.svg"),
        module: ["candidate"],
        children: []
      }
    ]
  },
  // {
  //   name: "nav-sidebar.courses",
  //   link: "/candidate/courses",
  //   icon: require("@/assets/icons/nav_sidebar/courses.svg"),
  //   active_icon: require("@/assets/icons/nav_sidebar/active/courses.svg"),
  //   module: "candidate",
  //   children: []
  // },
  {
    name: "nav-sidebar.settings",
    link: "/settings/account",
    icon: require("@/assets/icons/nav_sidebar/settings.svg"),
    active_icon: require("@/assets/icons/nav_sidebar/active/settings.svg"),
    module: ["candidate"],
    children: [
      {
        name: "nav-sidebar.account",
        link: "/settings/account",
        icon: require("@/assets/icons/nav_sidebar/settings.svg"),
        active_icon: require("@/assets/icons/nav_sidebar/active/settings.svg"),
        module: ["candidate"],
        children: []
      }
    ]
  },
  {
    name: "nav-sidebar.dashboard",
    link: "/recruiter/dashboard",
    icon: require("@/assets/icons/nav_sidebar/dashboard.svg"),
    active_icon: require("@/assets/icons/nav_sidebar/active/dashboard.svg"),
    module: ["recruiter", "recruiter_admin"],
    children: []
  },
  {
    name: "nav-sidebar.jobs",
    icon: require("@/assets/icons/nav_sidebar/jobs.svg"),
    active_icon: require("@/assets/icons/nav_sidebar/active/jobs.svg"),
    link: "/recruiter/jobs",
    module: ["recruiter", "recruiter_admin"],
    children: [
      // {
      //   name: "nav-sidebar.job-listing",
      //   link: "/recruiter/jobs",
      //   module: ["recruiter", "recruiter_admin"],
      //   children: []
      // }
      // {
      //   name: "nav-sidebar.post-job",
      //   module: ["recruiter", "recruiter_admin"],
      //   link: "/recruiter/jobs/post-job",
      //   children: []
      // }
    ]
  },
  {
    name: "nav-sidebar.candidates",
    link: "/recruiter/candidates",
    icon: require("@/assets/icons/nav_sidebar/candidates.svg"),
    active_icon: require("@/assets/icons/nav_sidebar/active/candidates.svg"),
    module: ["recruiter", "recruiter_admin"],
    children: [
      // {
      //   name: "nav-sidebar.candidate-listing",
      //   link: "/recruiter/candidates",
      //   module: ["recruiter", "recruiter_admin"],
      //   children: []
      // },
      // {
      //   name: "nav-sidebar.add-candidates",
      //   module: ["recruiter", "recruiter_admin"],
      //   link: "/recruiter/candidates/hiring",
      //   children: []
      // }
    ]
  },
  {
    name: "nav-sidebar.talent-pool",
    link: "/recruiter/talent-pool",
    icon: require("@/assets/icons/nav_sidebar/candidates.svg"),
    active_icon: require("@/assets/icons/nav_sidebar/active/teacher.svg"),
    module: ["recruiter", "recruiter_admin"],
    children: [
      // {
      //   name: "nav-sidebar.candidate-listing",
      //   link: "/recruiter/candidates",
      //   module: ["recruiter", "recruiter_admin"],
      //   children: []
      // },
      // {
      //   name: "nav-sidebar.add-candidates",
      //   module: ["recruiter", "recruiter_admin"],
      //   link: "/recruiter/candidates/hiring",
      //   children: []
      // }
    ]
  },
  // {
  //   name: "nav-sidebar.interviews",
  //   link: "/recruiter/interviews",
  //   icon: require("@/assets/icons/nav_sidebar/teacher.svg"),
  //   active_icon: require("@/assets/icons/nav_sidebar/active/teacher.svg"),
  //   module: ["recruiter", "recruiter_admin"],
  //   children: []
  // },
  // {
  //   name: "nav-sidebar.assessment",
  //   link: "/recruiter/assessments",
  //   icon: require("@/assets/icons/nav_sidebar/assessment.svg"),
  //   active_icon: require("@/assets/icons/nav_sidebar/active/assessment.svg"),
  //   module: ["recruiter", "recruiter_admin"],
  //   children: []
  // },
  // {
  //   name: "recruiter.knowledge-base.main-title",
  //   link: "/recruiter/chatbot",
  //   icon: require("@/assets/icons/nav_sidebar/knowledge-base.svg"),
  //   active_icon: require("@/assets/icons/nav_sidebar/active/knowledge-base.svg"),
  //   module: ["recruiter", "recruiter_admin"],
  //   children: []
  // },
  // {
  //   name: "nav-sidebar.skill-analytics",
  //   link: "/recruiter/skills-analytics",
  //   icon: require("@/assets/icons/nav_sidebar/employees.svg"),
  //   active_icon: require("@/assets/icons/nav_sidebar/active/employees.svg"),
  //   module: ["recruiter", "recruiter_admin"],
  //   children: []
  // },
  {
    name: "nav-sidebar.approvals",
    link: "/recruiter/admin/candidates",
    icon: require("@/assets/icons/nav_sidebar/approvals.svg"),
    active_icon: require("@/assets/icons/nav_sidebar/active/approvals.svg"),
    module: ["recruiter_admin"],
    children: []
  },
  // {
  //   name: "nav-sidebar.employees",
  //   link: "/recruiter/employees",
  //   icon: require("@/assets/icons/nav_sidebar/employees.svg"),
  //   active_icon: require("@/assets/icons/nav_sidebar/active/employees.svg"),
  //   module: "recruiter",
  //   children: []
  // },
  {
    name: "nav-sidebar.settings",
    link: "/settings/account",
    icon: require("@/assets/icons/nav_sidebar/settings.svg"),
    active_icon: require("@/assets/icons/nav_sidebar/active/settings.svg"),
    module: ["recruiter", "recruiter_admin"],
    children: [
      {
        name: "nav-sidebar.account",
        link: "/settings/account",
        module: ["recruiter", "recruiter_admin"],
        children: []
      }
    ]
  }
];

export default SidebarContent;
